import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import React from 'react';
import '../styles/About.css'
import i1 from "../images2/item1.png"
import i3 from "../images2/item3.png"
import i5 from "../images2/item5.png"
import i6 from "../images2/item6.png"
import i7 from "../images2/item7.png"
import i8 from "../images2/item8.png"


import last from "../images2/last.png"



const About = () => {
  return (
    <>
    <Navbar/>
      <div className="home">
        <div className="textHome">
            <div className="first-line">
                <p>More than just real state</p>
            </div>
            <div className="second-line">
                <p>We build</p>
                <p className="new provider"> Dreams</p>
            </div>
            <div className="description" style={{color: 'white'}}>
                <p>With a focus on quality craftsmanship and unwavering integrity, we bring your vision to reality. Experience the difference with our team</p>
            </div>
        </div>
    </div>
    <div className="a1">

        <div className="horizontal-rule-container">
            <div className="line1"><hr className="horizontal-rule1"/></div>
            
            <div className="project-title">
                <p className="text1">Learn more</p>
                <p className="text2">ABOUT US</p>
            </div>
            <div className="line2"><hr className="horizontal-rule2"/></div>
        </div>

        <div className="container">
            <div className="box item1">
                <img className="image_2" src={i1} alt="item1" />
            </div>
            <div className="box item2"></div>
            <div className="box item3">
                <img className="image_2" src={i3} alt="item1" />
            </div>
            <div className="box item4"></div>
            <div className="box item5">
                <img className="image_2" src={i5} alt="item1" />
            </div>
        </div>

        <div className="textMain">We believe in design as a process. We research into creating a nice environment that is adaptive and contextual. We see architecture as a language that speaks of the place. Our sensible, yet critical design approach recognizes the inherent complexity in modern day building and harness this complexity to produce projects that are culturally robust, place sensitive and environmentally friendly.</div>
        
        <div className="since">Since 2017</div>
        <div className="textMain2">
            “We are Reliable, trustworthy and we uphold the highest standards of integrity in all of our designs.”
                                        Balavenkatesh S - Director
        </div> 
        <div className="bar"></div>  
    </div>

    <div className="a2">
        <div className="new-box">
            <div className="super">
                <div className="head">
                    Why choose AARGAA ?
                </div>
                <div className="content">
                    We support every project with a specialized approach tailored to its unique requirements and challenges
                </div>
                
                    <div className="container2">
                        <div className="
                        box item9">
                            <div className="text_a2">
                                <h4>QUALITY SERVICE</h4>
                                <hr />
                                <p>Unmatched quality in every project we take</p>
                            </div>
                            
                        </div>
                        <div className="
                        box item10">
                            <div className="text_a2">
                                <h4>24 HOURS SERVICE</h4>
                                <hr />
                                <p>24/7 service tailored to your construction demands</p>
                            </div>
                            
                        </div>
                        <div className="
                        box item11">
                            <div className="text_a2">
                                <h4>MODERN TECHNOLOGIES</h4>
                                <hr />
                                <p>Embracing modern technologies for superior construction results</p>
                            </div>
                            
                        </div>
                        <div className="box item6">
                            <img className="images_a2" src={i6} alt="" />
                        </div>
                        <div className="box item7">
                            <img className="images_a2" src={i7} alt="" />
                        </div>
                        <div className="box item8">
                            <img className="images_a2" src={i8} alt="" />
                        </div>
                    </div>
            </div>
        </div>
    </div>
    <div className="a3">
        <div className="mainBox">
            <div className="comp">
                <div className="part1" style={{color: 'black'}}>PROJECT MANAGEMENT CONSULTANCY</div>
                <div className="part2" style={{color: 'black'}}>At every phase of the project life cycle, we maintain your requirements in mind, create a win-win scenario, and adhere to proactive principles. You can improve a construction project's efficiency by using PMC, which offers an efficient management solution</div>
                <div className="part3" style={{color: 'black'}}>SERVICES WE OFFER UNDER PMC</div>
                <div className="part4">
                    <ul>
                        <li style={{color: 'black'}}>1. Project Initiation</li>
                        <li style={{color: 'black'}}>2. Project Budgeting</li>
                        <li style={{color: 'black'}}>3. Project Monitoring</li>
                        <li style={{color: 'black'}}>4. Project Monitoring</li>
                    </ul>
                </div>
            </div>

            <div className="photo">
                <div className="inner">
                    <img className="lastImage" src={last} alt="last" />
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </>
  );
};

export default About;
