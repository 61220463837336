// import React from 'react';
// import '../styles/Navbar.css';
// import logo from '../assets/logo.png';

// const Navbar = () => {
//   return (
//     <nav>
//       <div className='nav-logo'>
//         <a href='/'>
//           <img src={logo} alt='logo' />
//         </a>
//       </div>
//       <div className='nav-main-link'>
//         <div className='nav-link'>
//           {' '}
//           <a href='/'>Home</a>
//         </div>
//         <div className='nav-link'>
//           {' '}
//           <a href='/services'>Services</a>
//         </div>
//         <div className='nav-link'>
//           {' '}
//           <a href='/gallery'>Gallery</a>
//         </div>
//         <div className='nav-link'>
//           {' '}
//           <a href='/about'>About</a>
//         </div>
//         <div className='nav-link'>
//           {' '}
//           <a href='/contact'>Contact</a>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;








import React, { useState } from "react";
import "../styles/Navbar.css";
import  web_logo  from '../assets/logo.png';

function Navbar() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else setActive("nav__menu");

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else setIcon("nav__toggler");
  };
  return (
    <nav className="nav">
      <a href="/" className="nav__brand">
        <img src={web_logo} alt='web_logo'></img>
      </a>
      <ul className={active}>
        <li className="nav__item">
          <a href="/" className="nav__link">
            Home
          </a>
        </li>
        <li className="nav__item">
          <a href="/services" className="nav__link">
            Services
          </a>
        </li>
        <li className="nav__item">
          <a href="/gallery" className="nav__link">
            Gallery
          </a>
        </li>
        <li className="nav__item">
          <a href="/about" className="nav__link">
            About us
          </a>
        </li>
        <li className="nav__item">
          <a href="/contact" className="nav__link">
            Contact 
          </a>
        </li>
      </ul>
      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
}

export default Navbar;