import React, { useState } from 'react'
import '../styles/Gallery.css'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../styles/Popup_image.css'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';
// import item1_1 from '../images3/item1_1.png';
// import item1_2 from '../images3/item1_2.png';
// import item1_3 from '../images3/item1_3.png';
// import item2_1 from '../images3/item2_1.jpeg';
// import item2_2 from '../images3/item2_2.jpeg';
// import item2_3 from '../images3/item2_3.jpeg';
// import item3_1 from '../images3/item3_1.jpeg';
// import item3_2 from '../images3/item3_2.png';
// import item3_3 from '../images3/item3_3.png';
// import item3_4 from '../images3/item3_4.png';
// import item4_1 from '../images3/item4_1.png';
// import item4_2 from '../images3/item4_2.png';
// import item4_3 from '../images3/item4_3.jpeg';
// import item4_4 from '../images3/item4_4.jpeg';
// import item5_1 from '../images3/item5_1.png';
// import item5_2 from '../images3/item5_2.png';
// import item5_3 from '../images3/item5_3.png';
// import item5_4 from '../images3/item5_4.png';
import item6_1 from '../images3/item6_1.png';
import item6_2 from '../images3/item6_2.png';
import item6_3 from '../images3/item6_3.png';
import item7_1 from '../images3/item7_1.png';
import item7_2 from '../images3/item7_2.png';
import item7_3 from '../images3/item7_3.png';
import item8_1 from '../images3/item8_1.jpeg';
import item8_2 from '../images3/item8_2.jpeg';
import item8_3 from '../images3/item8_3.jpeg';
import item9_1 from '../images3/item9_1.png';
import item9_2 from '../images3/item9_2.png';
import item9_3 from '../images3/item9_3.png';
import item10_1 from '../images3/item10_1.png';
import item10_2 from '../images3/item10_2.png';
import item10_3 from '../images3/item10_3.png';
import item11_1 from '../images3/item11_1.png';
import item11_2 from '../images3/item11_2.png';
import item12_1 from '../images3/item12_1.jpeg';
import item13_1 from '../images3/item13_1.png';
import item13_2 from '../images3/item13_2.png';
import item14_1 from '../images3/item14_1.jpeg';
import item14_2 from '../images3/item14_2.jpeg';
import item15_1 from '../images3/item15_1.png';
import item15_2 from '../images3/item15_2.png';
import item16_1 from '../images3/item16_1.png';
import item16_2 from '../images3/item16_2.png';
import item17_1 from '../images3/item17_1.png';
import item17_2 from '../images3/item17_2.png';
import item17_3 from '../images3/item17_3.png';
import item18_1 from '../images3/item18_1.png';
import item18_2 from '../images3/item18_2.jpeg';
import item18_3 from '../images3/item18_3.png';
import item19_1 from '../images3/item19_1.png';
import item19_2 from '../images3/item19_2.png';
import item20_1 from '../images3/item20_1.png';
import item20_2 from '../images3/item20_2.png';
import item20_3 from '../images3/item20_3.png';


const Gallery = () => {
  // -----------------------item1------------
  const [modal1, setModal1] = useState(false);
  const togglemodal1 = () => { setModal1(!modal1); };
  if (modal1) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }

  // -----------------------item2------------
  const [modal2, setModal2] = useState(false);
  const togglemodal2 = () => { setModal2(!modal2); };
  if (modal2) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // -----------------------item3------------
  const [modal3, setModal3] = useState(false);
  const togglemodal3 = () => { setModal3(!modal3); };
  if (modal3) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // -----------------------item4------------
  const [modal4, setModal4] = useState(false);
  const togglemodal4 = () => { setModal4(!modal4); };
  if (modal4) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // ------------------4----item5------------
  const [modal5, setModal5] = useState(false);
  const togglemodal5 = () => { setModal5(!modal5); };
  if (modal5) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // -----------------------item6------------
  const [modal6, setModal6] = useState(false);
  const togglemodal6 = () => { setModal6(!modal6); };
  if (modal6) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // -----------------------item7------------
  const [modal7, setModal7] = useState(false);
  const togglemodal7 = () => { setModal7(!modal7); };
  if (modal7) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // -----------------------item8------------
  const [modal8, setModal8] = useState(false);
  const togglemodal8 = () => { setModal8(!modal8); };
  if (modal8) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // -----------------------item9------------
  const [modal9, setModal9] = useState(false);
  const togglemodal9 = () => { setModal9(!modal9); };
  if (modal9) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // ----------------------item10------------
  const [modal10, setModal10] = useState(false);
  const togglemodal10 = () => { setModal10(!modal10); };
  if (modal10) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // -----------------------item11------------
  const [modal11, setModal11] = useState(false);
  const togglemodal11 = () => { setModal11(!modal11); };
  if (modal11) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // -----------------------item12------------
  const [modal12, setModal12] = useState(false);
  const togglemodal12 = () => { setModal12(!modal12); };
  if (modal12) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // -----------------------item13------------
  const [modal13, setModal13] = useState(false);
  const togglemodal13 = () => { setModal13(!modal13); };
  if (modal13) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // -----------------------item14------------
  const [modal14, setModal14] = useState(false);
  const togglemodal14 = () => { setModal14(!modal14); };
  if (modal14) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }
  // ----------------------item15------------
  const [modal15, setModal15] = useState(false);
  const togglemodal15 = () => { setModal15(!modal15); };
  if (modal15) { document.body.classList.add('active-modal') } else { document.body.classList.remove('active-modal') }


  return (
    <div>
      <Navbar />
      <div className="home_g"></div>
      <div className="g1_g">
        <div className="horizontal-rule-container_g">
          <div className="line1_g"><hr className="horizontal-rule1_g" /></div>
          <div className="project-title_g">
            <p className="text1_g">Projects</p>
            <p className="text2_g">RESIDENTIAL</p>
          </div>
          <div className="line2_g"><hr className="horizontal-rule2_g" /></div>
        </div>
        <div className="container_g">
          <div onClick={togglemodal1} className="box_g item1_g btn-modal"></div>
          {modal1 && (
            <div className="modal">
              <div onClick={togglemodal1} className="overlay"></div>
              <div className="modal-content">
                <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                  <SwiperSlide><img src={item6_1} alt="img1" /></SwiperSlide>
                  <SwiperSlide><img src={item6_2} alt="img2" /></SwiperSlide>
                  <SwiperSlide><img src={item6_3} alt="img3" /></SwiperSlide>
                </Swiper>
                <button className="close-modal" onClick={togglemodal1}> X </button>
              </div>
            </div>
          )}
          {modal2 && (
            <div className="modal">
              <div onClick={togglemodal2} className="overlay"></div>
              <div className="modal-content">
                <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                  <SwiperSlide><img src={item7_1} alt="img" /></SwiperSlide>
                  <SwiperSlide><img src={item7_2} alt="img" /></SwiperSlide>
                  <SwiperSlide><img src={item7_3} alt="img" /></SwiperSlide>
                </Swiper>
                <button className="close-modal" onClick={togglemodal2}> X </button>
              </div>
            </div>
          )}
          {modal3 && (
            <div className="modal">
              <div onClick={togglemodal3} className="overlay"></div>
              <div className="modal-content">
                <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                  <SwiperSlide><img src={item8_1} alt="img" /></SwiperSlide>
                  <SwiperSlide><img src={item8_2} alt="img" /></SwiperSlide>
                  <SwiperSlide><img src={item8_3} alt="img" /></SwiperSlide>
                </Swiper>
                <button className="close-modal" onClick={togglemodal3}> X </button>
              </div>
            </div>
          )}
          {modal4 && (
            <div className="modal">
              <div onClick={togglemodal4} className="overlay"></div>
              <div className="modal-content">
                <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                  <SwiperSlide><img src={item9_1} alt="img" /></SwiperSlide>
                  <SwiperSlide><img src={item9_2} alt="img" /></SwiperSlide>
                  <SwiperSlide><img src={item9_3} alt="img" /></SwiperSlide>
                </Swiper>
                <button className="close-modal" onClick={togglemodal4}> X </button>
              </div>
            </div>
          )}
          {modal5 && (
            <div className="modal">
              <div onClick={togglemodal5} className="overlay"></div>
              <div className="modal-content">
                <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                  <SwiperSlide><img src={item10_1} alt="img" /></SwiperSlide>
                  <SwiperSlide><img src={item10_2} alt="img" /></SwiperSlide>
                  <SwiperSlide><img src={item10_3} alt="img" /></SwiperSlide>
                </Swiper>
                <button className="close-modal" onClick={togglemodal5}> X </button>
              </div>
            </div>
          )}
          <div onClick={togglemodal2} className="box_g item2_g"></div>
          <div onClick={togglemodal3} className="box_g item3_g"></div>
          <div onClick={togglemodal4} className="box_g item4_g"></div>
          <div onClick={togglemodal5} className="box_g item5_g"></div>
        </div>
      </div>
      <div className="g2_g">
        <div className="horizontal-rule-container_g">
          <div className="line1_g"><hr className="horizontal-rule1_g" /></div>
          <div className="project-title_g">
            <p className="text1_g">Project</p>
            <p className="text2_g">COMMERCIAL</p>
          </div>
          <div className="line2_g"><hr className="horizontal-rule2_g" /></div>
        </div>
        <div className="container1_g">
          <div onClick={togglemodal6} className="box_g item6_g"></div>
          {modal6 && (
            <div className="modal">
              <div onClick={togglemodal6} className="overlay"></div>
              <div className="modal-content">
                <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                  <SwiperSlide><img src={item11_1} alt="img1" /></SwiperSlide>
                  <SwiperSlide><img src={item11_2} alt="img2" /></SwiperSlide>
                </Swiper>
                <button className="close-modal" onClick={togglemodal6}> X </button>
              </div>
            </div>
          )}
          {modal7 && (
            <div className="modal">
              <div onClick={togglemodal7} className="overlay"></div>
              <div className="modal-content">
                <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                  <SwiperSlide><img src={item14_1} alt="img" /></SwiperSlide>
                  <SwiperSlide><img src={item14_2} alt="img" /></SwiperSlide>
                </Swiper>
                <button className="close-modal" onClick={togglemodal7}> X </button>
              </div>
            </div>
          )}
          {modal8 && (
            <div className="modal">
              <div onClick={togglemodal8} className="overlay"></div>
              <div className="modal-content">
                <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                  <SwiperSlide><img src={item12_1} alt="img" /></SwiperSlide>
                </Swiper>
                <button className="close-modal" onClick={togglemodal8}> X </button>
              </div>
            </div>
          )}
          {modal9 && (
            <div className="modal">
              <div onClick={togglemodal9} className="overlay"></div>
              <div className="modal-content">
                <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                  <SwiperSlide><img src={item13_1} alt="img" /></SwiperSlide>
                  <SwiperSlide><img src={item13_2} alt="img" /></SwiperSlide>
                </Swiper>
                <button className="close-modal" onClick={togglemodal9}> X </button>
              </div>
            </div>
          )}
          {modal10 && (
            <div className="modal">
              <div onClick={togglemodal10} className="overlay"></div>
              <div className="modal-content">
                <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                  <SwiperSlide><img src={item15_1} alt="img" /></SwiperSlide>
                  <SwiperSlide><img src={item15_2} alt="img" /></SwiperSlide>
                </Swiper>
                <button className="close-modal" onClick={togglemodal10}> X </button>
              </div>
            </div>
          )}
          <div onClick={togglemodal7} className="box_g item7_g"></div>
          <div onClick={togglemodal8} className="box_g item8_g"></div>
          <div onClick={togglemodal9} className="box_g item9_g"></div>
          <div onClick={togglemodal10} className="box_g item10_g"></div>
        </div>
        {/* <div className="horizontal-rule-container-below_g">
          <div className="line1_g"><hr className="horizontal-rule1_g" /></div>
          <div className="project-title1_g">
            <p className="text1_g">Projects</p>
            <p className="text2_g">OTHERS</p>
          </div>
          <div className="line2_g"><hr className="horizontal-rule2_g" /></div>
        </div> */}
      </div>
      <br />

        <div className="second-section gallery-project-other">
          <h1 className="our-services "><span className="our">Project<br/> </span> Others </h1>
        </div>
      <div className="g3_g">
        <div className="centered-content_g">
          <div className="container2_g">
            <div onClick={togglemodal11} className="box_g item11_g"></div>
            {modal11 && (
              <div className="modal">
                <div onClick={togglemodal11} className="overlay"></div>
                <div className="modal-content">
                  <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                    <SwiperSlide><img src={item16_1} alt="img1" /></SwiperSlide>
                    <SwiperSlide><img src={item16_2} alt="img2" /></SwiperSlide>
                  </Swiper>
                  <button className="close-modal" onClick={togglemodal11}> X </button>
                </div>
              </div>
            )}
            {modal12 && (
              <div className="modal">
                <div onClick={togglemodal12} className="overlay"></div>
                <div className="modal-content">
                  <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                    <SwiperSlide><img src={item17_1} alt="img" /></SwiperSlide>
                    <SwiperSlide><img src={item17_2} alt="img" /></SwiperSlide>
                    <SwiperSlide><img src={item17_3} alt="img" /></SwiperSlide>
                  </Swiper>
                  <button className="close-modal" onClick={togglemodal12}> X </button>
                </div>
              </div>
            )}
            {modal13 && (
              <div className="modal">
                <div onClick={togglemodal13} className="overlay"></div>
                <div className="modal-content">
                  <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                    <SwiperSlide><img src={item18_1} alt="img" /></SwiperSlide>
                    <SwiperSlide><img src={item18_2} alt="img" /></SwiperSlide>
                    <SwiperSlide><img src={item18_3} alt="img" /></SwiperSlide>
                  </Swiper>
                  <button className="close-modal" onClick={togglemodal13}> X </button>
                </div>
              </div>
            )}
            {modal14 && (
              <div className="modal">
                <div onClick={togglemodal14} className="overlay"></div>
                <div className="modal-content">
                  <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                    <SwiperSlide><img src={item19_1} alt="img" /></SwiperSlide>
                    <SwiperSlide><img src={item19_2} alt="img" /></SwiperSlide>
                  </Swiper>
                  <button className="close-modal" onClick={togglemodal14}> X </button>
                </div>
              </div>
            )}
            {modal15 && (
              <div className="modal">
                <div onClick={togglemodal15} className="overlay"></div>
                <div className="modal-content">
                  <Swiper pagination={{ type: 'fraction', }} navigation={true} modules={[Pagination, Navigation]} className="mySwiper">
                    <SwiperSlide><img src={item20_1} alt="img" /></SwiperSlide>
                    <SwiperSlide><img src={item20_2} alt="img" /></SwiperSlide>
                    <SwiperSlide><img src={item20_3} alt="img" /></SwiperSlide>
                  </Swiper>
                  <button className="close-modal" onClick={togglemodal15}> X </button>
                </div>
              </div>
            )}
            <div onClick={togglemodal12} className="box_g item12_g"></div>
            <div onClick={togglemodal13} className="box_g item13_g"></div>
            <div onClick={togglemodal14} className="box_g item14_g"></div>
            <div onClick={togglemodal15} className="box_g item15_g"></div>
          </div>
        </div>
      </div>
      <Footer />

    </div>
  );
}

export default Gallery

