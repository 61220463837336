import React, { useState, useEffect } from 'react';
import '../styles/Home.css';
import anjappar from '../assets/anjappar.png';
import ars from '../assets/ars.png';
import bedroom from '../assets/bed_hover.png';
import csk from '../assets/csk.png';
import dindigul from '../assets/dindigul.png';
import dining_area from '../assets/dining_hover.png';
import karur from '../assets/karur.png';
import kitchen from '../assets/kitchen_hover.png';
import living_room from '../assets/living_hover.png';
import pratica from '../assets/pratica.png';
import rhythm from '../assets/rhythm.png';
import sattur from '../assets/sattur.png';
import vishwaa from '../assets/vishwaa.png';
import Footer from '../components/Footer.js';
import Navbar from '../components/Navbar';
import Flower_2 from '../assets/home_flower_2.png';
import pratica_img from '../assets/home_pratica.png';
import araa from '../assets/home_araa.jpg';
import vishwaa_img from '../assets/home_viswaa.png';

const Home = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [
    <img src={rhythm} alt='rhythm' className='home_rhythm_img' />,
    <img src={pratica_img} alt='practica' className='home_practica_img' />,
    <img src={araa} alt='araa' className='home_araa_img' />,
    <img src={vishwaa_img} alt='vishwaa' className='home_vishwaa_img' />,
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(timer);
  }, [images.length]);
  return (
    <div>
      <Navbar />
      <div className='image-container'>
        <div className='image-text'>
          <div className='image-title'>
            <h1>
              AARGAA CONSTRUCTIONS
              <br />
              PRIVATE LIMITED
            </h1>
          </div>
          <div className='image-subtitle'>
            <h2>VASTHU | PLANS | STRUCTURE | ELEVATION | INTERIORS | PMC</h2>
          </div>
        </div>
      </div>
      <div className='heading-container'>
        <div className='line'></div>
        <div className='text-container'>
          <div className='featured'>Featured</div>
          <h2>BUILDING SERVICES</h2>
        </div>
        <div className='line'></div>
      </div>
      <div id='grid'>
        <div className='kitchen'>
          <img src={kitchen} alt='kitchen' />
          <h1>KITCHEN</h1>
          <p>
            Open layout <br />
            <span>
              Roomy kitchen <br />
              Airy ambience
            </span>
          </p>
        </div>
        <div className='living-room'>
          <img src={living_room} alt='living-room' />
          <h1>LIVING ROOM</h1>
          <p>
            Vast lounge <br />
            <span>
              Wide living area <br />
              Abundant seating area
            </span>
          </p>
        </div>
        <div className='bedroom'>
          <img src={bedroom} alt='bedroom' />
          <h1>BEDROOM</h1>
          <p>
            Ample space <br />
            <span>
              Wide wardrobe <br />
              Broad bed area
            </span>
          </p>
        </div>
        <div className='dining-area'>
          <img src={dining_area} alt='dining-area' />
          <h1>DINING AREA</h1>
          <p>
            Open dining <br />
            <span>
              Generous dining area <br />
              Ample dining room
            </span>
          </p>
        </div>
      </div>
      <div className='home_stats'>
        <div className='stats-container'>
          <div className='stat'>
            <div className='number'>50+</div>
            <div className='label'>Successful Projects</div>
          </div>
          <div className='stat-divider'></div>
          <div className='stat'>
            <div className='number'>10</div>
            <div className='label'>Years Of Experience</div>
          </div>
          <div className='stat-divider'></div>
          <div className='stat'>
            <div className='number'>100+</div>
            <div className='label'>Elevations</div>
          </div>
          <div className='stat-divider'></div>
          <div className='stat'>
            <div className='number'>20+</div>
            <div className='label'>OnGoing Projects</div>
          </div>
        </div>
      </div>
      <div className='heading-container'>
        <div className='line'></div>
        <div className='text-container'>
          <div className='featured'>Prominent</div>
          <h2>RESIDENTIAL PROJECTS</h2>
        </div>
        <div className='line'></div>
      </div>
      <div className='resi'>
        <img src={sattur} alt='sattur' />
        <img src={karur} alt='karur' />
        <img src={dindigul} alt='dindigul' />
      </div>
      <div className='button-container'>
        <a href='/gallery'>
          <button className='view'>View More</button>
        </a>
      </div>
      <div className='heading-container' id='eminent'>
        <div className='line'></div>
        <div className='text-container'>
          <div className='featured'>Eminent</div>
          <h2>COMMERCIAL PROJECTS</h2>
        </div>
        <div className='line'></div>
      </div>
      {/* <div className='rhythm'>
        <img src={rhythm} alt='rhythm' className='home_rhythm_img' />
      </div>
      <div className='pratica'>
        <img src={pratica_img} className='home_practica_img' />
      </div>
      <div className='araa'>
        <img src={araa} alt='araa' className='home_araa_img' />
      </div>
      <div className='viswaa'>
        <img src={vishwaa_img} alt='vishwaa' className='home_vishwaa_img' />
      </div> */}
      <div className='carousel-container'>{images[currentImageIndex]}</div>

      <div className='heading-container'>
        <div className='line'></div>
        <div className='text-container'>
          <div className='featured'>Project Management Consultancy</div>
          <h2>PMC</h2>
        </div>
        <div className='line'></div>
      </div>
      <div className='home-content-container'>
        <img src={Flower_2} alt='flower' />

        <div className='content-container'>
          <p>
            With the help of our Project Management Consultancy (PMC) services,
            you may achieve the intended outcome and cost-effectiveness from
            project commencement to project conclusion. Our team has a proven
            track record of meeting budget, schedule, and quality goals while
            continuously delivering projects with exceptional safety and
            environmental performance to support our clients' business
            objectives.
          </p>
        </div>
      </div>
      <div className='button-container'>
        <a href='/about'>
          <button className='view'>View More</button>
        </a>
      </div>
      <div className='heading-container'>
        <div className='line'></div>
        <div className='text-container'>
          <div className='featured'>Our Notable</div>
          <h2>CLIENTS</h2>
        </div>
        <div className='line'></div>
      </div>
      <div className='logo-container_1'>
        <a href='https://www.chennaisuperkings.com/'>
          <img src={csk} alt='csk' />
        </a>
        <a href='https://www.anjappar.com/'>
          <img src={anjappar} alt='anjappar' />
        </a>
        <a href='https://arcivf.com/'>
          <img src={ars} alt='ars' />
        </a>
      </div>
      <div className='logo-container_2'>
        <a href='https://pratica.in/'>
          <img src={pratica} alt='pratica' />
        </a>
        <a href='https://www.instagram.com/vishwaabriyaani/?hl=en'>
          <img src={vishwaa} alt='vishwaa' />
        </a>
      </div>
      <div className='home_final_text_container'>
        <p>
          Mr. Gopi Bright Billboard | Mr. Sivakumar, Vishwaa Biriyani, Dindigul
          | Mr. Sivakumar, ARS Hospital, Karur | Sangeetha Electric & Co, Karur
          | Kanna Electricals, Dindigul | NPR Group of Institutions, Dindigul |
          Adilam M Mani, Candidate MP Dharmapuri | Thadangam A Subramani,
          District Secretary, Dharmapuri | Mr. Sakthivel, Sattur{' '}
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
